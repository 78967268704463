import { create } from '@storybook/theming/create'

import logo from './lincx-logo.svg'

export default create({
  brandTitle: 'Adverse Components',
  brandUrl: '/',
  brandImage: logo,
  brandTarget: '_self'
})
